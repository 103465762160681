import React from 'react';
import './Common.css';
import {Box} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {TextField} from '@mui/material';
import {Button} from '@mui/material';
import {isExpired, decodeToken} from "react-jwt";
import sign from "jwt-encode";
import {countryStateMapping} from "../countriesAndStates"


export default class ProgressiveForm extends React.Component {

    constructor(props) {
        super(props);

        let paramString = window.location.href.split('?')[1];

        this.state = {
            country: '',
            isCountrySet: true,
            selected_state: '',
            isStateSet: true,
            firstName: '',
            isFirstNameSet: true,
            lastName: '',
            isLastNameSet: true,
            isGuestPage: false,
            stateList: [],
            isSubmitDisabled: false,
            isCancelDisabled: false,
            auth0State: new URLSearchParams(paramString).get("state"),
            auth0SessionToken: new URLSearchParams(paramString).get("sessionToken")
        }

    }

    componentWillMount() {

        if (this.props.pagetype === "guest") {

            this.setState({
                isGuestPage: true,
                country: '',
                isCountrySet: true,
            })


        }
        this.setState({appInsights: this.props.appInsights})
    }

    ValidateState = (e, v) => {
        if (v) {
            this.setState({
                selected_state: v,
                isStateSet: true,
            })
        } else {
            this.setState({
                selected_state: '',
                isStateSet: false,
            })
        }
    }

    ValidateCountry = (e, v) => {
        if (v && countryStateMapping[v]) {
            this.setState({
                country: v,
                isCountrySet: true,
                stateList: Object.keys(countryStateMapping[v].statesList),
                selected_state: '',
            })
        } else {
            this.setState({
                country: '',
                isCountrySet: false,
                stateList: [],
                selected_state: '',
                isStateSet: false
            })
        }
    }

    ValidateTextBox = (event) => {
        if (event.target.value.length > 0) {
            switch (event.target.id) {
                case 'first_name':
                    this.setState({firstName: event.target.value})
                    this.setState({isFirstNameSet: true})
                    break;
                case 'last_name':
                    this.setState({lastName: event.target.value})
                    this.setState({isLastNameSet: true})
                    break;
                default:
                    break;
            }
        } else {
            switch (event.target.id) {
                case 'first_name':
                    this.setState({firstName: ''})
                    this.setState({isFirstNameSet: false})
                    break;
                case 'last_name':
                    this.setState({lastName: ''})
                    this.setState({isLastNameSet: false})
                    break;
                default:
                    break;
            }
        }
    }

    generateNewToken = (incomingData) => {

        const currentTime = Math.floor(Date.now() / 1000);
        const payload = {
            sub: incomingData.sub, // Mandatory, not validated
            iss: 'auth0-static-app',  // Optional, not validated
            iat: currentTime,
            exp: currentTime + 300,
            givenName: this.state.firstName, // Optional custom parameters to be used in Actions
            familyName: this.state.lastName, // Optional custom parameters to be used in Actions
            state: this.state.auth0State // Mandatory, validated by Auth0
        }
        if (this.state.isGuestPage) {
            payload.country = this.state.country;
            payload.countryState = this.state.selected_state;
            payload.countryCode = countryStateMapping[this.state.country].countryCode;
            payload.countryStateCode = countryStateMapping[this.state.country].statesList[this.state.selected_state] || '';
        }

        const token = sign(payload, this.state.auth0State);
        this.state.appInsights.trackEvent({name: 'New Token Generated', properties: {sub: incomingData.sub}});
        return token
    }


    // Validate the incomming token from Auth0
    validateIncomingToken = (sessionToken) => {
        if (!sessionToken) {
            this.state.appInsights.trackException({exception: new Error('No sessionToken found')});
            return {err: true}
        }

        try {
            const decodedToken = decodeToken(sessionToken);
            const isTokenExpired = isExpired(sessionToken);
            this.state.appInsights.trackEvent({
                name: 'Incoming Token Validated',
                properties: {incomingData: decodedToken, isTokenExpired: isTokenExpired}
            });
            return {incomingData: decodedToken, isTokenExpired: isTokenExpired, err: false}
        } catch (e) {
            this.state.appInsights.trackException({exception: new Error(`Invalid sessionToken: ${e}`)});
            return {err: true}
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        // Check for non empty firstName, lastName, country & state

        if (this.state.firstName.length < 2 || this.state.lastName.length < 2 || (this.state.isGuestPage && this.state.country.length < 1) || (this.state.isGuestPage && this.state.stateList.length > 0 && this.state.selected_state.length < 1)) {
            if(this.state.firstName.length < 2){
                this.setState({
                    isFirstNameSet: false
                })
            }
            if(this.state.lastName.length < 2){
                this.setState({
                    isLastNameSet: false
                })
            }
            if(this.state.isGuestPage && this.state.country.length < 1){
                this.setState({
                    isCountrySet: false
                })
            }
            if(this.state.isGuestPage && this.state.stateList.length > 0 && this.state.selected_state.length < 1){
                this.setState({
                    isStateSet: false
                })
            }

        } else {
            this.setState({
                isSubmitDisabled: true,
                isCancelDisabled: true
            })
            const response = this.validateIncomingToken(this.state.auth0SessionToken);
            let newToken, auth0Url;
            if (!response.err && !response.isTokenExpired) {
                newToken = this.generateNewToken(response.incomingData)
                auth0Url = `https://accounts-uat1.msci.com/continue?state=${this.state.auth0State}&token=${newToken}`
            } else {
                auth0Url = `https://accounts-uat1.msci.com/continue?state=${this.state.auth0State}`
                //TODO handle error (should we redirect and stop access or just through the error on react app (but this could be security issue))
            }
            window.location.href = auth0Url;
        }

    }

    handleCancel = (e) => {
        e.preventDefault();

        this.setState({
            firstName: '',
            isFirstNameSet: true,
            lastName: '',
            isLastNameSet: true,
            isSubmitDisabled: false
        })

        if (this.state.isGuestPage) {
            this.setState({
                country: '',
                isCountrySet: true,
                selected_state: '',
                isStateSet: true,
                stateList: []
            })
        }
    }

    render() {
        return (
            <Box className="form-body">
                <Box className="form-logo"></Box>
                <Box className="form-name">Complete User Profile</Box>
                <TextField
                    className="textfield"
                    id="first_name"
                    label="First Name"
                    variant="outlined"
                    value={this.state.firstName}
                    required
                    InputProps={{ style: { fontSize: '14px' } }}
                    InputLabelProps={{ style: { fontSize: '14px' } }}
                    onChange={this.ValidateTextBox}
                    error={!this.state.isFirstNameSet}
                />

                <TextField
                    className="textfield"
                    id="last_name"
                    label="Last Name"
                    variant="outlined"
                    value={this.state.lastName}
                    required
                    InputProps={{ style: { fontSize: '14px' } }}
                    InputLabelProps={{ style: { fontSize: '14px' } }}
                    onChange={this.ValidateTextBox}
                    error={!this.state.isLastNameSet}
                />


                <Autocomplete
                    className="textfield"
                    id="country"
                    value={this.state.country}
                    variant="standard"
                    required
                    getOptionLabel={(option) => option || ""}
                    isOptionEqualToValue={(option, value) =>
                        value === "" || option.id === value.id
                    }
                    renderOption={(props,option) => (
                        <div {...props} key={option} style={{ fontSize: '14px' }}>
                            {option}
                        </div>
                    )}
                    style={{display: this.props.pagetype === "guest" ? "block" : "none"}}
                    options={Object.keys(countryStateMapping)}
                    onChange={this.ValidateCountry}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Select Country"
                            required
                            InputProps={{ ...params.InputProps,style: { fontSize: '14px' } }}
                            InputLabelProps={{ style: { fontSize: '14px' } }}
                            error={!this.state.isCountrySet}/>}
                />


                <Autocomplete
                    className="textfield"
                    id="state"
                    value={this.state.selected_state}
                    variant="standard"
                    required
                    getOptionLabel={(option) => option || ""}
                    isOptionEqualToValue={(option, value) =>
                        value === "" || option.id === value.id
                    }
                    renderOption={(props,option) => (
                        <div {...props} key={option} style={{ fontSize: '14px' }}>
                            {option}
                        </div>
                    )}
                    style={{display: this.props.pagetype === "guest" && this.state.stateList.length > 0 ? "block" : "none"}}
                    options={this.state.stateList}
                    // disabled={this.state.stateList.length < 1 ? true : false}
                    onChange={this.ValidateState}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Select State"
                            required
                            InputProps={{ ...params.InputProps,style: { fontSize: '14px' } }}
                            InputLabelProps={{ style: { fontSize: '14px' } }}
                            error={!this.state.isStateSet}/>}
                />

                <Box display="flex" flexDirection="column" style={{paddingTop: '20px', gap: '10px', width: '100%'}}>
                    <Button variant="contained" style={{borderRadius: '100px', width: '100%', height: '50px', fontSize: '16px', textTransform: 'none'}}
                            disabled={this.state.isSubmitDisabled} onClick={this.handleSubmit}>Submit</Button>
                    <Button variant="outlined" style={{borderRadius: '100px', width: '100%', height: '50px', fontSize: '16px', textTransform: 'none'}}
                            disabled={this.state.isCancelDisabled} onClick={this.handleCancel} >Cancel</Button>
                </Box>
            </Box>
        )
    }

}